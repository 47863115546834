.error-text{
    -webkit-background-clip: text;
    font-size: 4.5rem;
    /* line-height: none; */
    font-weight: bold;
    background-image:linear-gradient(90deg, #6F58FF 0.41%, #00A3FF 100.41%) ;
    color: transparent;
    
}

.erro-desc{
    -webkit-background-clip: text;
    font-size: 1.6rem;
    font-weight: bold;
    background-image:linear-gradient(90deg, #6F58FF 0.41%, #00A3FF 100.41%) ;
    color: transparent;
    
}

.error-retry-btn{
    /* border: 1px solid white; */
    border-radius: 0.5rem;
    font-size: 1.6rem;
    padding: 0.5rem 1.6rem;
    cursor: pointer;
    border: none;

    display: flex;
    gap: 1rem ;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(90deg, #6F58FF 0.41%, #00A3FF 100.41%) ;
}

.reload-icon{
    transform: scaleX(-1);
}