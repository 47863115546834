.homefooter{
    position: relative;
    height: auto;
    min-height: 430px;
    width: 100%;
    background: #001427;
    overflow-x: hidden;
}
.logo-foot{
    width: 18vw;
    min-width: 150px;
    height: 11vh;
    min-height: 80px;
    margin-bottom: 35px;
}
.homefooter2{
    position: relative;
    height: auto;
    min-height: 430px;
    width: 100%;
    background: #2D0605;
}
.foot{
    display: flex;
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    color: white;
    font-size: 12px;
    align-items: center;
    padding: 10px 5vw;
}
.foot2{
    display: flex;
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    color: white;
    font-size: 12px;
    align-items: center;
    padding: 10px 5vw;
}
.foot-right{
    display: flex;
    margin-right: 10vw;
}
.text-term{
    margin-left: 50px;
}


 .foot-name, .foot-email, .foot-phone{
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    max-width: 500px;
}

.foot-button{
    color: white;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 100%));
    border-radius: 2px;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    width: 92%;
    max-width: 500px;
    margin: 15px 0px;
    cursor: pointer;
}
.foot-button2{
    color: white;
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    border-radius: 2px;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    width: 92%;
    max-width: 500px;
    margin: 15px 0px;
    cursor: pointer;
}
 .foot-sec2, .foot-sec3, .foot-sec4{
    width: 18%;
    padding-top: 10vh;
    min-height: 100px;
}
.foot-sec5{
    width: 40%;
}
.text-right{
    text-align: right;
}
.address, .telephone{
    margin-left: 20px;
    text-align: right;
}
 .foot-telephone, .foot-location{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}
.social-logo{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}
.foot-sec1{
    width: 25%;
    padding-right: 8vw;
}
.foot-text1{
    font-size: 24px;
    font-weight: 600;
}
.foot-text2{
    font-size: 10px;
}
.foot-text3{
    font-size: 16px;
}
.footer-top{
    padding: 2vh 5vw;
    display: flex;
    color: white;
    justify-content: space-between;
}
.footer-top-small{
    padding: 10vh 5vw;
    color: white;
    display: none;
}
.fb, .twitter, .insta, .likdin {
    padding-left: 30px;
    cursor: pointer;
  }
  .foot1-button-red{
    text-align: center;
    padding: 15px 15px;
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    color: white;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
    cursor: pointer;
    z-index: 99;
    margin: 10px 0px;
  }
  .foot1-button-blue{
    text-align: center;
    padding: 15px 15px;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 100%));
    color: white;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
    cursor: pointer;
    z-index: 99;
    margin: 10px 0px;
  }
@media (max-width:950px){
    .logo-foot{
        margin-bottom: 0;
    }
    .fb, .twitter, .insta, .likdin {
        padding-left: 0;
        padding-right: 30px;
      }
      .social-logo{
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
      }
    
    .footer-top{
        display: none;
    }
    .footer-top-small{
        display: block;
    }
    .foot-sec2, .foot-sec3, .foot-sec4, .foot-sec1, .foot-sec5{
        padding-top: 10px;
        width: 100%;
    }
    
    .address, .telephone{
        text-align: right;
    }
    .text-right{
        text-align: left;
    }
    .footer-popup p{
        font-size: 12px;
        display: inline-block;
    }
    .footer-popup {
        width: 80%;
    }
}
.footer-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    min-height: 200px;
  }
  
  .footer-popup p {
    margin: 0;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
  .footer-popup-span{
    display: block;
  }
  .footer-popup-img{
    width: 80px;
  }
  .error-message{
    margin-top: -4px;
    font-size: 12px;
    color: #CB5500;
  }