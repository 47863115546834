.form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    /* background-color: rgba(0, 0, 0, 0.99); */
    padding: 20px;
    border-radius: 10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    z-index: 1100;
    box-shadow: 0 0 10px #888888;
  }
  .form1 {
    display: flex;
    flex-direction: column;
    width: calc(100%-40px);
    /* box-shadow: 0 0 5px #888888; */
    /* border: 1px solid #333333; Added 'solid' for the border style */
    padding: 10px 20px;
    border-radius: 10px;
  }
  input,textarea, select, button{
    margin: 13px 0px;
    padding: 5px;
    font-size: 16px;
  }
  label{
    color: #333333;
  }
  .form1-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-button {
    cursor: pointer;
    font-size: 20px;
  }
  
  .close-button span {
    display: inline-block;
    color: gray;
    font-size: 40px;
    /* background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%)); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    display: block;
    cursor: pointer;
  }
  .close-button-red span {
    display: inline-block;
    font-size: 40px;
    color: gray;
    /* background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    display: block;
    cursor: pointer;
  }
  .form1-text1 {
    margin: 0;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    /* background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%)); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .form1-text1-red {
    margin: 0;
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  .form1-buttom {
    background: linear-gradient(to right, #1765AC, #1974CD); /* Adjust colors as needed */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .form1-buttom-red {
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .form1-buttom-red:hover {
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #e36d18 100%));
  }
  
  .form1-buttom:hover {
    background: linear-gradient(to right, #0056b3, #0080ff); /* Adjust colors as needed */
  }

  @media (max-width:600px){
    .form1{
      padding: 10px;
    }
    .form-popup{
      width: 85%;
    }
  }