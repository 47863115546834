.company-widget2{
    color: white;
    margin: 5vh 5vw;
}
.cw2-t1{
    font-size: 3vw;
    text-align: center;
    text-shadow: 0 40px 80px rgba(57, 136, 245, 0.6);
}
.cw2-t2{
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-bottom: -5px;
}
.cw2-t3{
    font-size: 16px;
    text-align: center;
}
.cw2-grid{
    margin: 5vh 2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.cw2-card{
    position: relative;
    height: 350px;
    width: 300px;
    margin: 10px;
    align-items: center;
}
.cw2-whitecard{
    height: 250px;
    color: #060606;
    border-radius: 20px;
    padding: 20px;
    background: white;
    margin-bottom: 50px;
    box-shadow: 0 4px 80px 0 rgba(57, 136, 245, 0.6);
}
.cw2-card-img1{
    position: absolute;
    top: 100px;
    width: 300px;
    padding: auto;
    margin-left: -25px;
}
.cw2-card-img2{
    position: absolute;
    top: 120px;
    width: 300px;
    padding: auto;
    margin-left: -20px;
}
.cw2-card-img3{
    position: absolute;
    top: 100px;
    width: 300px;
    padding: auto;
    margin-left: -30px;
}



@media (max-width:800px){
    .cw2-t1{
        font-size: 35px;
    }
}