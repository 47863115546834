.company-widget4{
    color: white;
}
.cw4-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cw4-left, .cw4-right{
    width: 50%;
}
.cw4-img{
    width: 100%;
}
.cw4-t2{
    padding-left: 5vw;
    font-size: 3.2vw;
}
.cw4-t3{
    padding-left: 5vw;
    font-size: 1.8vw;
}
.cw4-t4{
    font-size: 18px;
}
.cw4-button{
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    padding: 10px;
    width: auto;
    border-radius: 5px;
    text-align: center;
    width: 30vw;
    cursor: pointer;
}
.cw4-bh{
    margin-left: 5vw;
}
.cw4-indeed-icon{
    margin:0 8px;
    width: 18px;
    margin-bottom: -2px;
    transition: transform 1s ease-in-out;
}

.cw4-button:hover{
    .cw4-indeed-icon{
        margin-right: 20px;
    }
}



@media (max-width:800px){
    .cw4-container{
        display: block;
    }
    .cw4-left, .cw4-right{
        width: 100%;
    }
    .cw4-t2{
        padding-left: 0;
        font-size: 30px;
        text-align: center;
    }
    .cw4-t3{
        padding-left: 0;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }
    .cw4-t4{
        font-size: 14px;
    }
    .cw4-button{
        width: 220px;
    }
    .cw4-bh{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-left: 0;
    }
}