body{
    background-color: black;
    font-family: "Montserrat", SemiBold-600;
}

.navbarContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 4vw;
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    z-index: 1001;
    min-height: 100px;
}
.whitenavbarContainer{
    min-height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 4vw;
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    z-index: 1001;
}
.navImage {
    margin: 0vh 2vw;
    width: 18vw;
    cursor: pointer;
    min-width: 150px;
    height: 11vh;
    min-height: 80px;
}

.menuItems {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.2vw;
}
.menuItem {
    padding: 0px 2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuItem.active {
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.menuItem.active2{
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.menu-icon{
    width: 50px;
    height: 50px;
    background: #060606;
    color: #1765AC;
    display: none;
}
.menu-icon2{
    width: 50px;
    height: 50px;
    background: #060606;
    color: #CB0000;
    display: none;
   
}
.menu-icon-res{
    width: 50px;
    height: 50px;
    background: transparent;
    color: #1765AC;
    display: none;
}

.navButton {
    margin: 0vh 1vw;
    padding: 5px 20px;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    color: white;
    border-radius: 5px;
    align-items: center;
    display: flex;
    font-size: 1.1vw;
    font-weight: 600;
    cursor: pointer;
}

.navButton2 {
    margin: 0vh 1vw;
    padding: 5px 20px;
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    color: white;
    border-radius: 5px;
    align-items: center;
    display: flex;
    font-size: 1.1vw;
    font-weight: 600;
    cursor: pointer;
}
.overlay {
    position: fixed;
    height: 15vh;
    min-height: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 1); /* Adjust the alpha value for the desired transparency */
    z-index: 999; /* Set a high z-index to ensure the overlay appears above other content */
    pointer-events: none; /* Allow clicks to pass through the overlay to underlying elements */
    display: none; /* Initially hide the overlay */
}
.overlayWhite {
    position: fixed;
    height: 15vh;
    min-height: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255, 1); /* Adjust the alpha value for the desired transparency */
    z-index: 999; /* Set a high z-index to ensure the overlay appears above other content */
    pointer-events: none; /* Allow clicks to pass through the overlay to underlying elements */
    display: none; /* Initially hide the overlay */
}


.overlay.active , .overlayWhite.active{
    display: block;
}


.mobileMenu {
    position: fixed;
    top: 15vh;
    right: -100%;
    width: 90%;
    height: 85vh;
    min-height: 600px;
    /* background-color: #060B10; */
    background: transparent;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    z-index: 1001;
    animation: slideOut 0.5s forwards;
    padding: 0 5vw;

  }
  @media (max-height:525px){
    .mobileMenu{
        position: absolute;
    }
  }

  .mobileMenu.slide-in {
    animation: slideIn 0.5s forwards;
}
.mobileMenuBack{
    position: fixed;
    top:-100%;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    animation: topOut 0.5s forwards;
    background-color: #060B10;
}
.mobileMenuBack.top-in{
    animation: topIn 0.5s forwards;
}
/* Define the slide-in animation for the mobile menu */
@keyframes slideIn {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}
@keyframes slideOut {
    from {
        left: 0;
    }
    to {
        left: -100%;
    }
}

/* Define the top-in animation for the mobile menu */
@keyframes topIn {
    from {
        top: -100%;
    }
    to {
        top: 0;
    }
}
@keyframes topOut {
    from {
        top: 0;
    }
    to {
        top: -100%;
    }
}

  
  .mobileMenu .menuItem {
    cursor: pointer;
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
  .line{
    height: 1px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: white;
  }

  .logo-left2{
    display: flex;
    justify-content: space-between;
  }
  .mobilenavButton {
    margin: 1vh 0;
    padding: 5px 10px;
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    color: white;
    border-radius: 5px;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    justify-content: center;
}

.mobilenavButton2 {
    margin: 1vh 0;
    padding: 5px 10px;
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
    color: white;
    border-radius: 5px;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    justify-content: center;
}



@media (max-width:1115px){
    .menuItems, .navButton, .navButton2{
        font-size: 14px;
    }
}
@media (max-width:900px){
    .menuItems, .navButton, .navButton2{
        display: none;
    }
    .menu-icon, .menu-icon2, .menu-icon-res{
        display: block;
    }
}

.circle-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white; /* Adjust the color as needed */
    border-radius: 50%;
    cursor: pointer;
    color: black; /* Text color inside the circle */
    font-size: 22px; /* Adjust font size as needed */
    margin-left: 10px;
    /* padding: 0px 8px; */
    padding-bottom: 4px;
    height: 14px;
    width: 18px;
    -webkit-text-fill-color: black;
    text-align: center;
  }
  .circle-arrow.active{
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
  }
  .circle-arrow.active2{
    background: var(--Primary, linear-gradient(90deg, #CB0000 0%, #CB5500 100%));
  }


  .sub-menu{
    padding: 0px 7vw;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
