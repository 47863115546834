/* company_widget3.css */

.company-widget3 {
    position: relative;
    overflow: hidden;
    color: white;
  }
  .cw3-t2{
    color: white;
    font-size: 2.5vw;
    margin-top: -7vw;
  }
  
  .cw3-moving-cont {
    display: flex;
    transition: transform 0.5s ease;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vw;
  }
  
  .cw3-card {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .cw3-card:not(:first-child) {
    opacity: 0;
  }
  
  .cw2-card-img {
    border-radius: 20px;
    width: 100%;
    display: block;
    height: auto;
    min-height: 350px;
    object-fit: cover;
  }
  .cw3-card-container{
    position: relative;
  }
  /* .cw3-black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
} */
  .cw3-t2 {
    text-align: center;
  }
  
  /* Dot indicator styling */
  .dot-indicator {
    position: absolute;
    bottom: 5vw;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ddd;
    border-radius: 20px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.5s ease, width 0.5s ease; 
  }
  
  .dot.active {
    background-color: #1765AC;
    width: 60px;
  }
  

  @media (max-width:800px) and (min-width:600px){
    .cw3-t2{
        font-size:25px;
        margin-top: -10vw;
    }
  }
  
  @media (max-width:600px) and (min-width:450px){
    .cw3-t2{
        font-size: 22px;
        margin-top: -12vw;
    }
  }
  @media (max-width:450px) and (min-width:360px){
    .cw3-t2{
        font-size: 18px;
        margin-top: -50px;
    }
  }
  @media (max-width:360px){
    .cw3-t2{
        font-size: 18px;
        margin-top: -70px;
    }
  }