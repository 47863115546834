.company-widget1 {
    color: white;
    display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 85vh;
}

.cw1-left {
    padding-left: 5vw;
    width: 50%;
    z-index: 99;
}

.cw1right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.cw1-black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.zoom-container {
    position: relative;
    overflow: hidden;
    width: 70%;
    border-radius: 20px; /* Apply border radius to container, not the image */
    z-index: 99;
  }
  
  .cw1rightimg2 {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s ease-in-out;
    z-index: 99;
  }
  
  .zoom-container:hover .cw1rightimg2 {
    transform: scale(1.5, 1.5);
  }

.cw1-maintext {
    z-index: 99;
    font-weight: 500;
    font-size: 4.5vw;
    text-shadow: 0 4px 80px rgba(57, 136, 245, 0.6); /* Blue shadow */
}
.cw1-text2{
    font-size:1.6vw;
    font-weight:600;
    margin-bottom: 0px;
}
.cw1-text3{
    font-size:1.6vw;
    margin-top: 5px;
}
.cw1-address{
    display: flex;

}
.cw1-add-display{
    visibility: hidden;
}
.zoom-container:hover .cw1-add-display{
    visibility: visible;
}
.cw1-address-icon{
    margin-bottom: 15px;
    margin-right: 10px;
}
.cw1-span-text {
    background: var(--Primary, linear-gradient(90deg, #1765AC 0%, #1974CD 81.25%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    font-weight: 600;
}

.cw1-grid {
    position: absolute;
    bottom: 0;
    width: 150%;
    height: 480px;
    overflow: hidden;
    z-index: 1;
    margin-bottom: -15px;
}

.cw1-moving-grid {
    background: url("../../assets/images/BG_Animation.svg");
    animation: scrollBackgroundhw1 6s linear infinite;
    height: 480px;
    z-index: 1;
}


.cw1-wings {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    margin-bottom: 15px;
}

@media (max-width: 800px) {
    .cw1-wings{
        width:100vw;
    }
    .cw1-moving-grid {
        animation: scrollBackgroundhw2 8s linear infinite;
      }
    .cw1-add-display{
        visibility: visible;
    }
    .cw1-maintext {
        font-size: 7vw;
        text-align: center;
    }
    .company-widget1 {
        display: block;
    }
    .cw1-left, .cw1right {
        width: 100%;
    }
    .cw1-left {
        padding-left: 0;
        padding-bottom: 0;
        padding-right: 2vw;
    }
    .cw1-maintext {
        padding: 0 2vw;
    }
    .cw1-text2, .cw1-text3{
        font-size: 14px;
    }
}
